@import '../../style/global.scss';

.basemap-toggle.btn-map {
  position: absolute;
  left: 16px;
  top: 247px;
  background-color: $background;
  height: 44px;
  width: 64px;
  border: 1px solid $border;
  border-radius: 6px;

  background-image: url('../../static/coast.png');

  &.coast {
    background-image: url('../../static/enc.png');
  }
  &.enc {
    background-image: url('../../static/gray.png');
  }
  background-position: center;
  background-size: cover;

  &:focus {
    outline: none;
  }

  .loading-dots {
    span {
      width: 6px;
      height: 6px;
      margin: 2px;

      &:nth-child(4) {
        display: none;
      }
    }
  }
}
