@import '../style/colors.scss';

.popup-box--container {
  position: absolute;
  width: 460px;
  background: #f7f7f7;
  z-index: 1000;
  border: 1px solid #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;

  @media (max-width: 768px) {
    width: 80vw;
  }

  .popup-box--content {
    overflow-y: auto;
    padding: 0 10px;
    max-height: calc(50vh - 100px);
  }

  .popup-box--header {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    padding: 10px 20px;

    h3 {
      margin-top: 10px;
      font-size: 18px;
    }

    .popup-box--close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px;
      background: #f7f7f7;
      color: #82868a;
      border: none;
      font-size: 20px;

      &.btn,
      &:active,
      &:focus {
        box-shadow: none;
        outline: none;
        outline-offset: unset;
      }
    }
  }
}
