@import '../style/global';

.nav-bar--container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10001;
  background: rgba(247, 247, 247, 0.9);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 10px 0;
  text-align: right;
  height: 60px;

  .search--container {
    position: absolute;
    left: 130px;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-width: 340px;
  }

  .nav-bar--header-image {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    img {
      height: 56px;
    }
    position: absolute;
    top: -8px;
    left: 10px;
    z-index: 1000;
  }

  & > * {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .cms--nav a:not(.btn) {
    position: relative;
    display: inline-block;
    color: $themeColor;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
    padding: 8px 10px;

    &:after {
      display: block;
      content: ' ';
      @include absolute($left: 0, $bottom: 2px, $right: 0);
      @include size(10px, 2px);
      margin: auto;
      background: $themeColor;
      transition: all ease 200ms;
    }

    &:hover:after {
      width: 70%;
      opacity: 1;
    }
  }

  .nav-bar--hamburger {
    display: none;
  }

  @media (max-width: 900px) {
    .nav-bar--menu {
      float: right;
      margin-right: 10px;
    }

    .cms--nav {
      background: $bgColor;
      @include absolute($top: 60px, $right: 0);
      overflow: auto;
      opacity: 0;
      transition: opacity 200ms;
      pointer-events: none;
      box-shadow: -2px 5px 5px rgba(0, 0, 0, 0.1);
      a:not(.btn) {
        display: block;
        padding: 10px;

        &:after {
          display: none;
        }

        border-left: 2px solid $themeColor;
      }
    }

    .nav-bar--hamburger {
      display: block;
      @include square(48px);
      margin: 0 0 -42px;
      opacity: 0;
      cursor: pointer;
      z-index: 2;

      & ~ span {
        @include size(30px, 4px);
        display: block;
        border-radius: 2px;
        background: $themeColor;
        margin: 4px auto;
        transition: transform ease-in-out 200ms, opacity ease-in-out 100ms;
        opacity: 1;
        pointer-events: none;
      }

      &:checked ~ span:nth-child(2) {
        transform: translateY(8px) translateX(0) rotate(-45deg);
      }

      &:checked ~ span:nth-child(4) {
        transform: translateY(-8px) translateX(0) rotate(45deg);
      }

      &:checked ~ span:nth-child(3) {
        opacity: 0;
      }
    }

    .nav-bar--hamburger:checked ~ .cms--nav {
      pointer-events: all;
      height: auto;
      opacity: 1;
    }
  }

  .nav-bar--search-button {
    display: none;

    &:focus {
      outline: none;
    }

    @media (max-width: 550px) {
      display: block;
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      height: 60px;
      width: 48px;
      background: none;

      &.open {
        background: $themeColor;

        .icon {
          fill: #fff;
        }
      }

      span.icon {
        position: relative;
        display: block;
        width: 28px;
        height: 28px;

        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .search--container {
    @media (max-width: 550px) {
      display: none;
      top: 0;
      transform: none;

      &.open {
        display: inline-block;
        position: absolute;
        top: 100%;
        padding: 10px;
        background: $themeColor;
        right: 0;
        margin: 0;
        width: 100%;
        left: 0;
        max-width: none;

        .search--inputWrap:before {
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
        }
      }
    }
  }
}
