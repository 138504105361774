@import '../../style/global';

.routeInfo-legend {
  position: fixed;
  left: 10px;
  bottom: 10px;
  padding: 2px 12px;
  background: $background;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  @media (max-width: 768px) {
    top: 100px;
    right: 10px;
    left: auto;
    bottom: auto;

    font-size: 11px;
    padding: 0 7px;
  }

  p {
    position: relative;
    margin: 5px 0;
    padding-left: 28px;

    &:before {
      content: ' ';
      display: block;
      @include absolute($left: 0, $top: 50%);
      transform: translate(0, -50%);

      border-radius: 2px;
      background: #000;
    }

    &.legend-routes:before {
      background: #f00;
      @include size(18px, 4px);
    }
    &.legend-ports:before {
      background: #00f;
      @include square(14px);
      left: 2px;
    }
    &.legend-portFacilities:before {
      background: #000;
      @include square(12px);
      left: 3px;
    }
    &.legend-quays:before {
      background: #0f0;
      @include square(10px);
      left: 4px;
    }
  }
}
