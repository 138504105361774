@import '../style/global';

.userForm-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;

  .userForm-content {
    @include contentBox();
    @include absolute($top: 80px, $left: 50%);
    transform: translateX(-50%);
    padding: 15px;
    max-height: calc(100vh - 120px);
    overflow: auto;
    background: #fff;
    @media (max-width: 768px) {
      top: 80px;
      transform: none;
      height: auto;
    }

    .btn-link {
      background: none;
      border: none;
      font-weight: 600;
      color: #102c43;
      text-decoration: underline;

      &:focus {
        outline: none;
      }
    }

    form {
      margin-bottom: 20px;

      .form-group {
        &.privacy {
          background: $mapColor;
          padding: 1px 10px 4px;
          label {
            display: inline-block;
            padding: 5px;
            font-weight: 400;

            input {
              margin-right: 10px;
              vertical-align: -2px;
            }
          }
        }

        .form-control {
          &.error {
            border-color: #bf1650;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
          }
        }
      }

      .form-control--error {
        color: #bf1650;
      }

      .btn {
        &.btn-secondary {
          margin-right: 15px;
        }

        &:focus {
          outline: none;
        }
      }

      small {
        display: block;
      }

      .below {
        margin-top: 20px;
        a,
        button.btn-link {
          margin-top: 10px;
          display: block;
          padding: 5px 0;
        }
      }
    }

    .profile-title--container {
      position: relative;

      h2 {
        display: inline-block;
        font-weight: 600;
        margin: 20px 0;
      }
      .success {
        @include absolute(50%, 0);
        transform: translateY(-50%);
        color: $themeColor2;
        padding: 0 20px;
        display: inline-block;
        opacity: 1;
        transition: opacity 200ms ease;

        &.hide {
          transition: opacity 200ms ease;
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}
