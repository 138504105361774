.feature-select--list-container {
  padding: 5px;
  margin: 0;

  .feature-select--list-item {
    list-style: none;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: -1px;
    cursor: pointer;
    word-wrap: break-word;

    &:hover {
      background: white;
    }
  }
  @import '../../style/sources.scss';
}
