$bgColor: #f7f7f7;
$mapColor: #e2f4ff;

$themeColor: #000667;
$themeColor2: #007d97;
$accentColor: #e9510e;

$fontColor: #333;

// OpenBridge styling
$background: #ffffff;
$font: #000000;
$whiteFont: #fff;
$border: #707070;
$checkbox: #000000;
$btnBackgroundHover: #cccccc;
$backgroundFaqbox: #f0f0f0;
