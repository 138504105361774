@import '../../style/global';

.loading {
  position: relative;
  min-height: 100px;

  & > div {
    @include absolute(0, 0, 0, 0);
    height: 40px;
    margin: auto;
    text-align: center;

    .icon {
      display: inline-block;
      @include size(20px, 20px);
      @include rotate(1.3s, reverse);

      path {
        fill: rgba(0, 0, 0, 0.8);
      }
    }

    span {
      display: block;
    }
  }
}
