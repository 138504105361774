@import '../style/global';

.article-detail-view--container {
  @include contentBox();
  position: fixed;
  top: 80px;
  right: 0;
  max-height: calc(100vh - 80px);
  padding: 15px;
  overflow: auto;

  .closeBtn {
    position: static;
    float: right;
    background: #102c43;
    border-color: #06111a;
    padding: 6px 12px;
    border-radius: 0;
    &:focus {
      outline: none;
    }
    .glyphicon-remove::before {
      color: white;
    }
  }

  .article {
    h3 {
      word-wrap: break-word;
      margin-top: 20px;
      font-size: 20px;
      font-weight: 600;
    }

    h4 {
      margin-top: 20px;
      font-weight: 600;
    }

    .articletype {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 5px;
      font-size: 14px;
      font-weight: 600;
    }

    @import '../style/sources.scss';

    .additional {
      margin: 10px 0;
      h5 {
        display: inline;
        font-weight: bold;
        &:after {
          content: ': ';
        }
      }
      p {
        display: inline;
      }
    }

    .subList {
      display: flex;
      label {
        flex: 1 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      input[type='checkbox'] {
        margin-right: 10px;
      }
      .btn-link {
        padding: 0;
      }
    }

    .loading {
      @include absolute(0, 0, 0, 0);
      background: rgba(255, 255, 255, 0.8);
    }

    .locations-after--download-btn-container {
      margin-top: 20px;
    }
  }
}
