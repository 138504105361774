.icon {
  svg {
    height: 1em;
    vertical-align: text-top;
  }

  &.r90 {
    svg {
      transform: rotate(90deg);
    }
  }

  &.r180 {
    svg {
      transform: rotate(180deg);
    }
  }

  &.r270 {
    svg {
      transform: rotate(270deg);
    }
  }
}

.icon-list {
  margin: 20px auto;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;

  & > div {
    padding: 10px;
    width: 400px;

    .icon {
      margin-right: 10px;
    }
  }
}
