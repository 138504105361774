@import '../../style/global';

.article-list {
  // max-height: calc(100vh - 167px);
  // overflow-y: auto;
  padding-bottom: 1px;

  .article {
    border: 1px solid #ccc;
    margin: 0 0 -1px;
    display: block;
    color: #333;
    text-decoration: none;
    cursor: pointer;
    padding: 15px;

    &:hover:not(btn) {
      background: #fff;
    }
  }
  h4 {
    margin: 0;
    font-size: 14px;
    word-wrap: break-word;
  }

  @import '../../style/sources.scss';

  .no-results {
    padding: 20px;
    text-align: center;
  }
}
