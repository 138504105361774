@import '../style/global';

.article-view--container {
  .article-view--content {
    @include contentBox();
    position: fixed;
    top: 80px;
    right: 0;
    max-height: calc(100vh - 80px);
    padding: 15px;
    overflow: auto;

    .articles--container {
      background: none;
    }

    .articleList--thumbnail img {
      display: none;
    }
    .articleList--thumbnail.articleList--withThumbnail {
      min-height: 150px;
      .caption {
        max-height: 100%;
        max-height: calc(100% - 10px);
        overflow: auto;
      }
      img {
        display: inherit;
      }
    }

    .article-view--info {
      text-align: left;
      font-style: normal;
      font-size: 13px;
    }
  }

  .buttons {
    position: absolute;
    top: 0;
    right: 440px;

    & > * {
      display: block;
    }

    @media (max-width: 1260px) {
      right: 400px;
    }

    @media (max-width: 768px) {
      top: 60%;
      right: 20px;
      transform: translate(0, -100%);

      & > * {
        display: inline-block;
      }
    }
  }
}
