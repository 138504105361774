@import '../../style/global';

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}

.loading-dots {
  margin-left: 5px;
  span {
    display: inline-block;
    width: 5px;
    height: 5px;
    animation: pulse 1.5s linear infinite;
    background-color: $themeColor;
    border-radius: 50%;
    margin-right: 6px;
  }

  span:nth-child(2) {
    animation-delay: 100ms;
  }
  span:nth-child(3) {
    animation-delay: 200ms;
  }
  span:nth-child(4) {
    animation-delay: 300ms;
  }
}
