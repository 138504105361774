@import '../style/global';

.disclaimer--container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100000;

  & > div {
    @include absolute($top: 50%, $left: 50%);
    transform: translate(-50%, -50%);
    width: 50vw;
    @media (max-width: 768px) {
      width: 90vw;
    }
    max-width: calc(100vw - 20px);
    max-height: calc(80vh);
    overflow: auto;
    background: $bgColor;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);

    .bar {
      & > * {
        display: inline-block;
        margin-left: 15px;
      }
      text-align: right;
    }

    .warning label {
      color: #c00;
      font-weight: bold;
    }
  }
}
