@import '../style/global.scss';

.collapsible--root {
  margin: 10px 0;
  border-bottom: 1px solid #ccc;
  background: rgba(255,255,255,1);
  padding: 5px;
  border-radius: 6px;

  .collapsible--header{
    font-weight: 600;
    display: inline-block;
    width: 100%;
    padding: 5px;

    .icon {
      height: 24x;
      width: 24px;
      float: right;
    }
  }

  .collapsible--content {
    padding: 5px;
    display: none;
  }

  .collapsible--content.open {
    display: block;
  }
}
