@import './colors';

@mixin size($width: $width, $height: $height) {
  height: $height;
  width: $width;
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin pos($top: false, $right: false, $bottom: false, $left: false) {
  @if $top {
    top: $top;
  }
  @if $right {
    right: $right;
  }
  @if $bottom {
    bottom: $bottom;
  }
  @if $left {
    left: $left;
  }
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}

@keyframes rotationAnim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin rotate($speed: 1.5s, $direction: normal) {
  animation: rotationAnim $speed linear infinite $direction;
}

@mixin contentBox($lgWidth: 440px, $mdWidth: 400px) {
  background: $bgColor;
  border: 1px solid #eee;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: $lgWidth;

  @media (max-width: 1260px) {
    width: $mdWidth;
  }

  @media (max-width: 768px) {
    @include pos($top: 60vh, $left: 10px, $right: 10px);
    width: auto;
    margin-bottom: 10px;
    bottom: 0;
    max-height: 40vh;
  }
}
