@import '../../style/global';

.search--container {
  position: relative;

  .form-control {
    border-radius: 0;
    background: #fff;
    border: none;
    border-bottom: 2px solid $themeColor;
    box-shadow: none;

    &:focus {
      box-shadow: none;
      border-color: $accentColor;
    }

    padding-left: 40px;
  }

  .search--inputWrap:before {
    content: url('data:image/svg+xml; utf8, <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" version="1.1"><path fill="%23102c43" d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" /></svg>');
    display: block;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    z-index: 1;
    pointer-events: none;
  }

  input:not(:placeholder-shown) + .btn-link {
    opacity: 0.8;
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .btn-link.clear-search {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $themeColor;
    opacity: 0.3;
    outline: none;
    &:active,
    &:focus {
      outline: none !important;
    }

    @media (max-width: 550px) {
      right: 10px;
    }
  }

  .search--searchSources {
    position: absolute;
    background: #fff;
    max-height: calc(100vh - 200px);
    width: 100%;
    overflow: auto;
    z-index: 2;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
    pointer-events: all;
    border: 1px solid #ddd;

    &:focus {
      outline: none;
    }
    .searchSource--container {
      color: #000;
      text-align: left;

      button.btn-link {
        position: relative;
        color: #102c43;
        opacity: 0.5;
        font-size: 13px;
        float: right;
        transform: none;
      }

      &:focus {
        outline: none;
      }

      h2 {
        display: inline-block;
        margin: 10px 10px 5px;
        opacity: 0.8;
        font-weight: normal;
        font-size: 14px;
        max-width: 70%;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 10px 15px;
          cursor: pointer;
          border-bottom: 1px solid rgba(221, 221, 221, 0.5);

          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }

          h3 {
            font-size: 14px;
            margin: 0;
          }
        }
      }
    }
    position: fixed;
  }
}
