@import '../style/global';

.page-view--container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  font-size: 16px;
  color: rgb(0, 0, 0);

  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  h1 {
    padding: 0 0 20px 0;
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }

  a.btn {
    display: inline-block;
    width: auto;
    min-width: 96px;
    height: 40px;
    padding: 0 16px;
    line-height: 40px;
    border-radius: 6px;
    background: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.6);
    margin: 5px;
    font-weight: 600;
    font-size: 16px;
    color: rgb(0, 0, 0);

    &.alert {
      background: rgba(255, 213, 0, 1);
      border: 1px rgba(255, 213, 0, 1);
    }

    &.raised {
      background: rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(0, 0, 0, 0.12);
      color: rgb(255, 255, 255);
    }
  }

  .page-view--content {
    position: absolute;
    top: 80px;
    left: 50%;
    max-width: 1000px;
    width: 100%;
    transform: translateX(-50%);
    min-height: 20vh;
    background: $background;
    max-height: calc(100vh - 80px);
    overflow-y: auto;

    @media (max-width: 1000px) {
      left: 0;
      right: 0;
      width: 100%;
      transform: none;
    }

    div.content {
      padding: 20px;
    }

    h1 {
      text-align: center;
      font-weight: 800;
    }

    .btn-container {
      text-align: center;
      margin: 20px 0;
    }

    .table {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      h4,
      p {
        padding: 0 15px;
      }

      h4.header {
        padding: 4px 15px;
        font-weight: 800;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    .app {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      padding: 15px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          font-weight: 800;
          padding: 5px;

          a {
            font-weight: 400;
          }
        }
      }
    }

    .download-container {
      margin: 20px 0;
      font-weight: 600;

      .btn {
        margin: 10px 0;
      }

      p {
        font-weight: 400;
      }
    }

    footer.kvblue {
      text-align: center;
      background: $themeColor;
      color: rgb(255, 255, 255);
      padding: 10px;

      a {
        color: rgb(255, 255, 255);
        margin-bottom: 0;
      }
    }

    content {
      background: #f7f7f7;
    }

    .faqbox {
      background: $backgroundFaqbox;
      margin: 10px;
      padding: 10px;
    }

    .latest h4 {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 10px;
    }
  }
}
