.port {
  border-left: 3px solid rgb(0, 0, 255);
}

.route {
  border-left: 3px solid rgb(255, 0, 0);
}

.portfacility {
  border-left: 3px solid rgb(0, 0, 0);
}

.quay {
  border-left: 3px solid rgb(0, 255, 0);
}

.cpr {
  border-left: 3px dotted rgb(0, 0, 0);

  &.red {
    border-left-color: rgb(255, 86, 86);
  }
  &.blue {
    border-left-color: rgb(0, 127, 255);
  }
}

.mtr {
  border-left: 3px solid rgb(255, 255, 255);
}

.svalbard {
  border-left: 3px dotted rgb(255, 136, 0);
}
