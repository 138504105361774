@import '../../style/global.scss';

.layer-switch {
  position: absolute;
  top: 296px;
  left: 16px;
  height: 44px;
  width: 64px;
  background: $background;
  border: 1px solid $border;
  border-radius: 6px;
  overflow: hidden;
  transition: max-height 3s ease;
  cursor: pointer;
  z-index: 10;

  &.open {
    overflow: visible;
    height: auto;
    max-height: 400px;
    width: 210px;
  }

  .layer-switch--icons {
    position: relative;
    height: 44px;
    width: 64px;
    text-align: center;
    margin-top: 9px;

    .icon {
      display: inline-block;
      height: 26px;
      width: 26px;

      svg {
        height: 26px;
        width: 26px;
      }
    }
  }

  .layer-switch--layers {
    padding: 0 14px;

    .layer-switch--layer {
      display: inline-grid;
      grid-column-gap: 10px;
      grid-template-columns: auto auto;

      label {
        font-weight: normal;
      }
    }
  }
}
