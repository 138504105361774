@import url('//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
@import './style/fonts';
@import './style/global';

body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;

  .react-openlayers--map {
    position: fixed;
    @include size(100vw, 100vh);
    // background: $mapColor;
  }

  .thumbnail,
  .btn {
    border-radius: 0;
  }

  .btn {
    font-size: 14px;
  }

  .btn-primary {
    background: $themeColor;
    border-color: darken($themeColor, 10%);

    &:hover {
      background: desaturate(lighten($themeColor, 10%), 10%);
    }
  }

  .btn-info {
    background: $themeColor2;
    border-color: darken($themeColor2, 10%);

    &:hover {
      background: desaturate(lighten($themeColor2, 10%), 10%);
    }
  }

  .btn-link {
    color: $themeColor;
    &:hover {
      color: lighten($themeColor, 20%);
    }

    &[disabled] {
      color: #777;
      cursor: default;
    }
  }

  input,
  textarea,
  .form-control {
    border-color: #ddd;
  }

  .react-openlayers--show-location {
    &.ol-unselectable {
      &.ol-control {
        top: 100px;
        background: none;
        left: 16px;
        border-radius: 0;
        padding: 0;

        button {
          border-radius: 6px;
          width: 64px;
          height: 44px;
          background: $background;
          border: 1px solid $border;
          opacity: 1;
          margin: 0px;

          svg {
            fill: $font;
          }

          &:hover {
            background: $btnBackgroundHover;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .zoom-control--root {
    top: 149px;
    left: 16px;
    padding: 0;
    border-radius: 0;
    background: none;

    button {
      height: 44px;
      width: 64px;
      opacity: 1;
      background: $background;
      border-radius: 6px;
      border: 1px solid $border;
      margin: 0px;

      svg {
        fill: $font;
      }

      &:hover {
        background: $btnBackgroundHover;
      }

      &:focus {
        outline: none;
      }
      &.zoom-out {
        margin-top: 5px;
      }
    }
  }

  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 12px;
  }

  .icon {
    @include square(16px);
  }
}

a:not(.btn),
a:visited:not(.btn) {
  color: $themeColor;
  text-decoration: underline;
}
a:hover:not(.btn) {
  color: lighten($themeColor, 10%);
}

.rotating {
  @include rotate();
}

.splash--screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    max-width: 450px;

    @media (max-width: 600px) {
      max-width: 80vw;
    }
  }

  span.loading-dots {
    display: block;
    text-align: center;

    span {
      height: 8px;
      width: 8px;
    }
  }
}
