@import '../style/global';

.core-layout--root {
  box-sizing: border-box;
  @include absolute(0, 0, 0, 0);
  pointer-events: none;
  margin: 0;

  & > * {
    pointer-events: all;
  }

  @media (max-width: 768px) {
    margin: 0;
  }

  .kv-logo {
    display: none;
    pointer-events: none;
    @include absolute($bottom: 0, $left: 0);
    width: 140px;
    max-width: calc(100vw - 20px);
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    padding: 10px;

    img {
      max-width: 100%;
    }

    &:before {
      content: 'Under development';
      @include absolute($top: 50%, $left: 0);
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      text-shadow: 0 0 5px #000;
      transform: translate(0, -50%) rotate(-15deg);
    }

    @media (max-width: 768px) {
      @include pos(50px, 10px, auto, auto);
    }
  }

  .gdpr-disclaimer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: $background;
    text-align: center;
    padding: 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

    span.text {
      display: inline-block;
      margin: 0 45px;
    }

    button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background: $background;
      border: 1px solid $border;

      &:hover {
        background: $btnBackgroundHover;
      }

      .icon {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .dev-banner {
    position: fixed;
    top: 100px;
    padding: 10px;
    background: red;
    color: white;
    left: 50%;
    transform: translateX(-50%);
  }
}

.core-layout--components {
  .search--container {
    margin-bottom: 15px;

    .search--searchSources:not(:empty) {
      position: absolute;
      max-height: calc(100vh - 200px);
      width: 100%;
    }
  }
}
